import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { graphql } from 'gatsby';
import ClientCarouselCard from '../../molecules/ClientCarouselCard/ClientCarouselCard';
import Layout from '../../organisms/Layout/Layout';
import SmbSlantBanner from '../../organisms/SmbSlantBanner/SmbSlantBanner';

// renamed to match Sanity schema
import oneColumnSection from '../../organisms/OneColumnSection/OneColumnSection';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import ctaCardsSection from '../../organisms/CtaCardsSection/CtaCardsSection';
import clientTile from '../../molecules/ClientTile/ClientTile';
import clientTileSection from '../../organisms/ClientTileSection/ClientTileSection';
import multiSection from '../../organisms/MultiSection/MultiSection';
import { renderPageSections } from '../renderPageSections';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import useLocalize from '../../../hooks/useLocalize';

const AllSmbClientsPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityAllSmbClientsPage === null) {
    return null;
  }
  const data = useLocalize(rawData, locale);

  const allSmbClientsPageProps = {
    id: data.sanityAllSmbClientsPage._id,
    allSmbClientsSlantBanner:
      data.sanityAllSmbClientsPage._rawAllSmbClientsSlantBanner,
    backgroundImage: data.sanityAllSmbClientsPage._rawBackgroundImage,
    metadata: { ...data.sanityAllSmbClientsPage._rawRoute, href },
    sections: data.sanityAllSmbClientsPage._rawSections,
    bubbleCtaSection: data.sanityAllSmbClientsPage._rawBubbleCtaSection,
  };

  const sectionSchemaName = {
    oneColumnSection,
    ctaCardsSection,
    clientTile,
    clientTileSection,
    multiSection,
  };

  const backgroundAndSlantBannerCss = css`
    overflow: hidden;
    background-repeat: repeat-y;
    background-size: ${pageDimensions.largeDesktopWidth};

    .slant-banner {
      position: relative;
      z-index: 2;

      section:last-of-type {
        margin-top: ${uc('-250px')};

        @media (${breakpoints.tablet}) {
          margin-top: ${uc('30px')};
        }
      }
    }
  `;

  const bgImage = {
    backgroundImage: `url(${sanityImage(allSmbClientsPageProps.backgroundImage)
      .auto('format')
      .width(1400)
      .fit('max')
      .url()})`,
  };

  const allSmbClientsSlantBannerCss = css`
    @media (${breakpoints.smallDesktopMax}) {
      padding-bottom: ${uc('160px')};
    }
  `;

  return (
    <Layout
      menuColor="white"
      sanityId={allSmbClientsPageProps.id}
      {...allSmbClientsPageProps.metadata}
      locale={locale}
    >
      <div css={backgroundAndSlantBannerCss} style={bgImage}>
        <SmbSlantBanner
          templateSpecificStyles={allSmbClientsSlantBannerCss}
          {...allSmbClientsPageProps.allSmbClientsSlantBanner}
        >
          <ClientCarouselCard
            {...allSmbClientsPageProps.allSmbClientsSlantBanner
              .clientCarouselCard}
          />
        </SmbSlantBanner>
        {renderPageSections(allSmbClientsPageProps.sections, sectionSchemaName)}
        {allSmbClientsPageProps.bubbleCtaSection && (
          <BubbleCtaSection {...allSmbClientsPageProps.bubbleCtaSection} />
        )}
      </div>
    </Layout>
  );
};

AllSmbClientsPage.propTypes = {
  data: PropTypes.shape({ sanityAllSmbClientsPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

AllSmbClientsPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export default AllSmbClientsPage;

export const query = graphql`
  query($slug: String) {
    sanityAllSmbClientsPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawBackgroundImage(resolveReferences: { maxDepth: 100 })
      _rawAllSmbClientsSlantBanner(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      _id
    }
  }
`;
